<!-- 现金钱包,账服通钱包 充值,提现 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20">
			<div class="font-size18 font-color-333 font-weight700 margin-t-15">
				{{title}}
			</div>
			<div class="margin-t-6 recharge-wallet-box position-relative wallet-box">
				<div class="box">
					<div class="card-box flex-row-align-center">
						<div class="user-img-box margin-r-6 flex-row-align-center">
							<imageBox class='user-img' :width='60' :height='60' :borderRidus='50' :src="url"></imageBox>
						</div>
						<div class="flex-colum user-name-box">
							<div class="font-size15 font-weight700">{{ getUserInfo.customerName }}</div>
							<div class="font-size14 margin-t-10 flex-row-wrap">
								<div class="margin-r-5">钱包金额:</div>
								<div class="font-color-FF0000 margin-r-10">{{maxAmount|NumFormat}}元</div>
								<div v-if="withdrawalsShow" style="margin-right: 10px;" class="color-theme underline pointer" @click="viewTheDetails">锁定金额</div>
								<div v-if="type=='withdrawal'" class="color-theme underline pointer" @click="allwithdrawal">全部提现</div>
							</div>
								<!-- <div class="margin-r-5 margin-t-10">锁定金额:</div>
								<div class="font-size14 flex-row-wrap">
								<div style="color: #a3a3a3;" class="margin-r-10">{{fLockMoney|NumFormat}}元</div>
								
								<div v-if="type=='withdrawal'" class="color-theme underline pointer" @click="viewTheDetails">查看明细</div>
							</div>
							<div v-if="withdrawalsShow" class="font-size14 margin-t-10 flex-row-wrap">
								<div class="margin-r-5">可提现金额:</div>
								<div style="color: #a3a3a3;" class="margin-r-10">{{maxAmount|NumFormat}}元</div>
								
							</div> -->
						</div>
					</div>
					<el-form v-if="title == '账服通提现'" ref="form" :rules="rules" :model="form" label-position="right">
						<el-form-item label="提现卡：" prop="fAccountID">
							<el-select v-model="form.fAccountID" placeholder="请选择提现卡" ref="fRefundMode">
								<el-option style="width: 220px;" v-for="(item,index) in fRefundModeList" :key="index" :label="item.fShowName"
								 :value="item.fAccountID"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
					<div class="recharge-amount-box flex-row-align-center border-bottom-F2F2F2">
						<div class="font-size14 font-color-333 recharge-amount-text">{{type=='recharge'?'充值':'提现'}}金额:</div>
						<div class="font-size30 font-color-333">
							￥
						</div>
						<el-input ref="amount" :autofocus="true" v-model="amount" @input="setOnlyMoney" :maxlength="13"></el-input>
					</div>
					<div v-loading='buttonLoading' class="recharge-button font-size14 background-color-theme flex-row-center-center pointer"
					 @click="changePayment">立即{{type=='recharge'?'充值':'提现'}}</div>
				</div>

			</div>

		</div>
		<viewTheDetails ref="child" :showFlag="showFlag" @closeChildDialog="closeChildDialog"  ></viewTheDetails>
		<!-- 线下退款的弹框告知-->
		<el-dialog :visible.sync="newagreement" width="40%":close-on-click-modal="false"
			:close-on-press-escape="false" class="homeDialog">
			<div class="notice-content">
				<div style="height: 100%;background-color: white;">
					<div class="noticeContent">您当前的提现金额不满足线上提现条件，是否同意进入线下提现方式</div>
					<div class="noticeContent">线下提现需在线提供上传加盖公章的提现申请及收款收据并邮寄至聚材公司</div>	
					<div style="width:100%;display: flex;justify-content: space-around;margin-top: 35px;">
						<div class="noticeBtn"@click="noAgree">不同意</div>
						<div class="noticeBtn"@click="yesAgree">同意</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 设置支付密码 -->
		<setPaymentPassword v-if="setPaymentPasswordVisible" :setPaymentPasswordVisible="setPaymentPasswordVisible"
		  @change="setPaymentPassword"></setPaymentPassword>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import viewTheDetails from './viewTheDetails/viewTheDetails.vue'
	import imageBox from '@/components/imageBox/imageBox.vue'
	import setPaymentPassword from '@/components/payment/setPaymentPassword.vue'
	export default {
		components: {
			imageBox,
			viewTheDetails,
			setPaymentPassword
		},
		data() {

			return {
				setPaymentPasswordVisible: false, //设置支付密码弹框
				numberOfPassword: 0, //输入密码次数
				pwdList: [], //密码数组
				password: '', //支付密码
				PasswordVisible: false, //支付弹窗
				newagreement:false,
				rules: {
					fAccountID: [{
						required: true,
						message: '请选择提现卡',
						trigger: 'change'
					}],
				},
				form: {
					fAccountID: '',
				},
				fRefundModeList: [],
				title: '', //标题
				type: '', //类型 充值:recharge  提现:withdrawal
				walletType: '', //钱包类型 现金钱包:XJ 账服通钱包:ZFT
				amount: '', //金额
				maxAmount: 0, //账户余额
				fLockMoney:0,//锁定金额
				url: require("@/assets/personal.png"),
				buttonLoading: false, //按钮加载
				actualAmount: '', //不带千分符的金额
				showFlag :false,//查看明细的表格显示隐藏
				withdrawalsShow:false,//提现金额的显示与隐藏
			};
		},
		created() {
		  var that = this
		  //当前页面监视键盘输入
		  document.onkeydown = function(e) { //事件对象兼容
		    // console.log(e, e.key);
		    if (that.PasswordVisible && that.$route.fullPath == '/paymentOrder') {
		      if (e.key == '0' || e.key == '1' || e.key == '2' || e.key == '3' || e.key == '4' || e.key == '5' || e.key ==
		        '6' ||
		        e.key == '7' || e.key == '8' || e.key == '9') {
		        if (that.pwdList.length < 6) {
		          that.pwdList.push(e.key)
		          that.password = that.pwdList.join('')
		        }
		        if (that.pwdList.length == 6) {
		          that.numberOfPassword = that.numberOfPassword + 1;
		        }
		      } else if (e.key == 'Backspace') {
		        that.pwdList.pop()
		      }
		    }
		  }
		},
		mounted() {
			this.$refs.amount.focus();
			let data = JSON.parse(decodeURIComponent(this.$route.query.data));
			this.type = data.type;
			this.walletType = data.walletType;
			if (this.walletType == 'ZFT') {
				if (this.type == 'withdrawal') {
					this.title = '账服通提现'
				} else {
					this.title = '账服通充值'
				}
				this.getfRefundModeList()
				this.getBalanceZFT()
			}
			if (this.walletType == 'XJ') {
				if (this.type == 'withdrawal') {
					this.title = '钱包提现'
				} else {
					this.title = '钱包充值'
				}
				this.getBalanceXJ()
				this.getlockXJ()
			}
			if (this.getUserInfo.fFileName) {
				this.url = this.getUserInfo.fFileName
			}
		},
		computed: {
			...mapGetters(["getThemeName", "getUserInfo"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			//查询支付密码
			getPayPassword() {
				this.buttonLoading = true;
				this.ApiRequestPostNOMess('api/mall/ebcustomer/baseinfo/exist-walletpwd').then(res => {
					this.buttonLoading = false;
					if (res.obj == 1) { //有支付密码
						this.customerPaymentBillQBCZ();
					}else{
						this.$confirm('亲,当前账户未设置支付密码,是否去设置?', '提示', {
						  confirmButtonText: '去设置',
						  cancelButtonText: '取消',
						  type: 'info'
						}).then(() => {
						  //打开设置密码弹框
						  this.setPaymentPasswordVisible = true;
						}).catch(() => {});
					}
				}, error => {
					this.buttonLoading = false;
				});
			},
			//设置支付密码
			setPaymentPassword(e) {
			  if (e.isSet) {
			    console.log('设置成功');
			    this.numberOfPassword = 0;
			    this.getPayPassword()
			  }
			  this.setPaymentPasswordVisible = e.show
			},
			//判断是否需要进行线下退款
			customerLineUnder() {
				this.buttonLoading = true;
				this.ApiRequestPostNOMess('api/mall/ebsale/refund-bill/get-is-offline', {
					fMoney: this.actualAmount,
					fAppTypeID: this.fAppTypeID,
				}).then(res => {
					this.buttonLoading = false;
					if(res.obj.fIsOffline == 1){
						this.newagreement = true
						//走的线下退款
					}else{
						//直接走的线上
						this.customerPaymentBillQBTX()
					}
				}, error => {
					this.buttonLoading = false;
				});
			},
			//同意线下
			yesAgree(){
				this.newagreement = false
				this.customerPaymentBillQBTX()
			},
			//不同意线下
			noAgree(){
				this.newagreement = false
			},
			//查看明细的显示隐藏
			closeChildDialog(){
				this.showFlag = false
			},
			//获取下拉框的数据
			getfRefundModeList() {
				this.ApiRequestPostNOMess('/api/mall/ebcustomer/bank-account/get-acs-physical-withdraw-account-list').then(result => {
					if (result.obj.length > 0) {
						this.fRefundModeList = result.obj
					}
				}, rej => {})
			},
			//获取账服通余额
			getBalanceZFT() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-acs-virtual-account')
					.then(res => {
						console.log("账服余额", res);
						if (res.obj) {
							this.maxAmount = res.obj.fEndBalance ? res.obj.fEndBalance : 0
						}
					}, error => {});
			},
			//获取锁定金额
			getlockXJ(){
				this.ApiRequestPostNOMess('api/mall/ebactivity/gather-task-record/get-my-cash-new').then(result=>{
					console.log('锁定金额',result)
					if(result.obj.fIsLocking == 1){
						// this.fLockMoney = result.obj.fLockMoney ? result.obj.fLockMoney : 0
						this.withdrawalsShow = true
					}else {
						this.withdrawalsShow = false
					}
				},error=>{})
			},
			//获取现金钱包余额
			getBalanceXJ() {
				this.ApiRequestPostNOMess('api/mall/ebbalance/customer-cash/get-my-cash')
					.then(res => {
						console.log("钱包余额", res);
						if (res.obj.length > 0) {
							this.maxAmount = res.obj[0].fEndBalance ? res.obj[0].fEndBalance : 0
						}
					}, error => {});
			},
			//全部提现
			allwithdrawal() {
				let val = this.maxAmount.toString()
				this.actualAmount = this.maxAmount.toString()
				let num = val.split("."); // 分隔小数点
				let arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
				let res = [];
				for (let i = 0, len = arr.length; i < len; i++) {
					if (i % 3 === 0 && i !== 0) {
						res.push(","); // 添加分隔符
					}
					res.push(arr[i]);
				}
				res.reverse(); // 再次倒序成为正确的顺序
				if (num[1]) { // 如果有小数的话添加小数部分
					if (num[1].length == 1) {
						res = res.join("").concat("." + num[1] + '0');
					} else {
						res = res.join("").concat("." + num[1]);
					}
				} else {
					res = res.join("").concat("." + "00");
				}
				this.amount = res;
			},
			//查看明细
			viewTheDetails(){
				this.showFlag = true
				this.$refs.child.getLockRecord()
				this.$refs.child.LockXJDetails()
			},
			//输入金额限制
			setOnlyMoney() {
				this.$nextTick(() => {
					let val = this.amount.toString();
					val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
					val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
					val = val.replace(/^0+\./g, '0.');
					val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
					val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || '';
					this.actualAmount = val
					let res = [];
					if (val.includes(".")) {
						let num = val.split("."); // 分隔小数点
						let arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
						for (let i = 0, len = arr.length; i < len; i++) {
							if (i % 3 === 0 && i !== 0) {
								res.push(","); // 添加分隔符
							}
							res.push(arr[i]);
						}
						res.reverse(); // 再次倒序成为正确的顺序
						if (num[1]) { // 如果有小数的话添加小数部分
							res = res.join("").concat("." + num[1]);
						} else {
							res = res.join("").concat(".");
						}
					} else {
						let num = val.split("."); // 分隔小数点
						let arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
						for (let i = 0, len = arr.length; i < len; i++) {
							if (i % 3 === 0 && i !== 0) {
								res.push(","); // 添加分隔符
							}
							res.push(arr[i]);
						}
						res.reverse(); // 再次倒序成为正确的顺序
						res = res.join("");
					}
					this.amount = res;
				});
			},
			changePayment() {
				if (!this.actualAmount) {
					this.$message('请输入金额');
					return
				}
				if (this.actualAmount == 0) {
					if (this.type == 'withdrawal') {
						this.$message('提现金额不能为0');
					} else {
						this.$message('充值金额不能为0');
					}
					return
				}
				if (this.walletType == 'XJ') { //现金钱包
					if (this.type == 'recharge') { //生成钱包充值单
						this.getPayPassword()
					} else if (this.type == 'withdrawal') { //钱包提现
						if (this.actualAmount > this.maxAmount) {
							this.$message('您所提现的金额超出当前账户余额，请重新输入');
						} else if (this.actualAmount > 99999999.99) {
							this.$message('最大提现金额为99,999,999.99.99，请重新输入');
						} else {
							this.$confirm('是否立即提现？', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消'
							}).then(() => {
								this.customerLineUnder()
								// this.customerPaymentBillQBTX();
							}).catch(() => {});
						}
					}
				} else if (this.walletType == 'ZFT') { //账服通钱包
					if (this.type == 'recharge') { //账服通充值
						//生成账服通充值单
						this.customerPaymentBillZFTCZ();
					} else if (this.type == 'withdrawal') { //账服通提现
						if (this.actualAmount > this.maxAmount) {
							this.$message('您所提现的金额超出当前账户余额，请重新输入');
						} else if (this.actualAmount > 99999999.99) {
							this.$message('最大提现金额为99,999,999.99.99，请重新输入');
						} else {
							this.$refs.form.validate((valid) => {
								if (valid) {
									this.customerPaymentBillZFTTX();
								} else {
									return false;
								}
							});
						}
					}
				}
			},
			//现金钱包充值
			customerPaymentBillQBCZ() {
				console.log(this.actualAmount > 99999999.99);
				if (this.actualAmount > 99999999.99) {
					this.$message('最大提充值金额为99,999,999.99，请重新输入');
					return
				}
				console.log("现金钱包充值");
				this.$router.replace({
					name: "PaymentOrder",
					params: {
						fBillTypeID: '200000805',
						money: this.actualAmount,
						title: this.title
					}
				})
			},
			//现金钱包提现
			customerPaymentBillQBTX() {
				if (!this.buttonLoading) {
					this.buttonLoading = true;
					this.ApiRequestPostNOMess('api/mall/ebsale/refund-bill/create-cash-return', {
							fMoney: this.actualAmount,
							fAppTypeID: this.fAppTypeID,
						})
						.then(res => {
							console.log("钱包提现___完成11", res);	
							// Auditing字段 1表示审核中 0表示初始化 254 表示审核通过
							if (res.obj.status) {
								let obj = {
									successType: 'TX',
									examine: res.obj.Auditing,
									amount: this.actualAmount,
									billNumber: res.obj.fRefundBillNumber,
									reID: res.obj.fRefundBillID,
									fSettleModeID:res.obj.fSettleModeID,
									type: 'XJ',
									fBillTypeID: '200000805',
								}
								this.$router.replace({
									path: '/businessme/RechSuccess',
									query: {
										// data: encodeURIComponent(JSON.stringify(obj))
										data: JSON.stringify(obj),
									}
								})
								this.buttonLoading = false;
							} else {
								this.$message({
									message: res.obj.message,
									type: 'warning'
								});
								this.buttonLoading = false;
							}
						}, error => {
							this.buttonLoading = false;
						});
				}

			},
			//账服通提现
			customerPaymentBillZFTTX() {
				this.ApiRequestPostNOMess('api/mall/ebsale/refund-bill/create-zft-return', {
						fMoney: this.actualAmount,
						fAppTypeID: this.fAppTypeID,
						fAccountID: this.form.fAccountID
					})
					.then(res => {
						console.log("账服通提现___完成", res);
						// Auditing字段 1表示审核中 0表示初始化 254 表示审核通过
						if (res.obj.status) {
							let obj = {
								successType: 'TX',
								examine: res.obj.Auditing,
								amount: this.actualAmount,
								billNumber: res.obj.fRefundBillNumber,
								type: 'ZFT',
								fBillTypeID: '200015019',
							}
							this.$router.replace({
								path: '/businessme/RechSuccess',
								query: {
									// data: encodeURIComponent(JSON.stringify(obj))
									data: JSON.stringify(obj)
								}
							})
						} else {
							this.$message({
								message: res.obj.message,
								type: 'warning'
							});
						}
					}, error => {});
			},
			// 生成账服通充值单
			customerPaymentBillZFTCZ() {
				if (this.actualAmount > 99999999.99) {
					this.$message('最大提充值金额为99,999,999.99.99，请重新输入');
					return
				}
				console.log("账服通充值");
				this.$router.replace({
					name: "PaymentOrder",
					params: {
						fBillTypeID: '200015019',
						money: this.actualAmount,
						title: this.title
					}
				})
			},
		}

	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.card-box {
		// width: 350px;
		height: 100px;
	}

	.user-name-box {
		color: #1F4E9E;
	}

	.user-img-box {
		width: 80px;
		height: 80px;
	}

	.user-img {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	}

	.recharge-wallet-box {
		// padding: 14px 0 0 20px;
		width: 897px;
		height: 378px;
		background: url(../../../assets/imgs/pay/web01.jpg) no-repeat;
		// background-size: 100% 378px;

	}

	.wallet-box {
		height: 400px;
		box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
		box-sizing: border-box;
		padding-top: 30px;
		margin-top: 10px;
		border-radius: 5px;
	}

	.box {
		min-height: 260px;
		width: 255px;
		margin-left: 95px;
		padding: 20px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		background-color: #FFFFFF;
	}

	.back-card-logo-img {
		width: 50px;
		height: 20px;
		background-color: #CCCCCC;
	}

	.changeBankCard:hover {
		cursor: pointer;
	}

	.recharge-amount-box {
		margin-top: 20px;
		height: 44px;
		width: 255px;
		padding-bottom: 10px;
	}

	.recharge-amount-text {
		width: 70px;
	}

	.recharge-button {
		margin: 40px 0 0 0px;
		// width: 235px;
		width: 255px;
		height: 45px;
		border-radius: 0px;
	}

	.underline {
		text-decoration: underline;
	}

	.recharge-amount-box::v-deep {
		.el-input__inner {
			// width: 100px;
			height: 44px !important;
			font-size: 24px;
			padding: 0;
			color: #000000;
			border: none !important;
			background-color: rgba(255, 255, 255, 0);
		}

		.el-input {
			width: 155px;
		}
	}

	/deep/ .el-form-item__label {
		padding: 0;
	}

	/deep/ .el-select {
		width: 185px;
	}
	.notice-content {
		width:100%;
		overflow: hidden;
	}
	.noticeBtn{
		width:150px;
		line-height: 35px;
		background-color: #005CC5;
		color:white;
		text-align: center;
	}
	.noticeContent{
		font-size: 18px;
		margin-top: 20px;
		text-align: center;
	}
	/deep/ .el-dialog{
		margin-top: 30vh !important;
	}
</style>
