<template>
	<el-dialog :close-on-click-modal="false" title="锁定金额记录" :visible.sync="dialogTableVisible" @close="closeDialog"
		width="80%">
		<el-table :row-key="getNum" :expand-row-keys="NumberList"  @expand-change="expandChange" v-loading="tableLoading" style="margin-bottom: 10px;" :height="tableHeight2" border :data="gridData">
			<el-table-column  type="expand">
				<template slot-scope="props">
					<el-form label-position="left" inline class="demo-table-expand" v-for="(item,index) in props.row.fOrderRef" :key="index">
						<el-form-item label="订单编号: ">
							<span>{{ item.fOrderNumber}}</span>
						</el-form-item>
						<el-form-item label="订单金额: ">
							<span>{{item.fOrderMoney}}</span>
						</el-form-item>
						<el-form-item label="解锁金额: ">
							<span>{{item.fUnlockMoney}}</span>
						</el-form-item>
						<el-form-item label="创建人: ">
							<span>{{ item.fCreator }}</span>
						</el-form-item>
						<el-form-item label="创建时间: ">
							<span>{{ item.fCreateTime }}</span>
						</el-form-item>
						<el-form-item label="修改人: ">
							<span>{{ item.fMender }}</span>
						</el-form-item>
						<el-form-item label="修改时间: ">
							<span>{{ item.fModifyTime }}</span>
						</el-form-item>
					</el-form>
				</template>
			</el-table-column>
			<el-table-column v-for="(item,index) in tableLabel1" :key="index" :prop="item.prop" :width="item.width"
				:label="item.label" :align="item.align" :fixed="item.fixed"></el-table-column>
		</el-table>
	</el-dialog>
</template>
<script>
	export default {
		props: {
			showFlag: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				tableHeight2: 'calc(70vh - 300px)', //明细表格高度
				tableLoading: false, // 表格 加载等待 变量
				tableLoading2: false, // 表格 加载等待 变量
				detailsShow: false, //明细显示与隐藏
				dialogTableVisible: false,
				innerVisible: false, //内部遮罩层
				gridData: [],
				expands:[],
				detailsData: [], //明细表格
				tableLabel1: [{
						prop: 'fTaskRecord.fModifyTime',
						label: '任务领取时间',
						width: '165',
						align: 'center',
					},
					{
						prop: 'fTaskRecord.fBillNumber',
						label: '任务编号',
						width: 'auto',
						align: 'left',
					},
					{
						prop: 'fTaskRecord.fTitle',
						label: '任务名称',
						width: 'auto',
						align: 'left',
					},
					{
						prop: 'fTaskRecord.fGatheringMoney',
						label: '充值金额',
						width: 'auto',
						align: 'right',
					},
					{
						prop: 'fTaskRecord.fLockMoney',
						label: '锁定金额',
						width: 'auto',
						align: 'right',
					}
				],
				searchText: '', //搜索内容
				searchText1: '', //搜索记录明细内容
				skipCount: 0,
				total1: 0, //明细总条数
				currentPage1: 1, //明细当前页数
				maxResultCount: 10, //每页最大个数
				total: 0, //总条数
				pagesize: 10, //每页最多个数
				currentPage: 1, //当前页数
				loading: false,
				item: '',
				NumberList : [],
				num : ''
			};
		},
		mounted() {
			this.getLockRecord()
		},
		methods: {
			getNum(row){
				return row.fTaskRecord.fBillID
			},
			//获取锁定金额明细
			expandChange(e){
				console.log('e',e)
			},
			//获取锁定金额记录
			getLockRecord() {
				// console.log(row.id)
				this.tableLoading = true
				this.gridData = []
				console.log(1111)
				this.ApiRequestPostNOMess('api/mall/ebactivity/gather-task-record/get-my-gather-task-record-list').then(res => {
						console.log('获取锁定金额记录', res)
						if (res.obj.length > 0) {
							if(res.obj[0].fOrderRef.length > 0){
								this.NumberList = [res.obj[0].fTaskRecord.fBillID]
							}
							this.gridData = res.obj
							for (let item of this.gridData) {
								console.log('item',item)
								item.fTaskRecord.fModifyTime = this.getTime(item.fTaskRecord.fModifyTime)
								for(let time of item.fOrderRef){
									console.log(item.fOrderRef)
									time.fModifyTime = this.getTime(time.fModifyTime)
									time.fCreateTime = this.getTime(time.fCreateTime)
									this.NumberList.push(time.fOrderNumber ? time.fOrderNumber : '1' )
								}
								
							}
						}
						console.error(this.NumberList)
						// this.num = this.NumberList[0]
						// this.expandChange(res.obj[0])
						this.$forceUpdate()
						this.tableLoading = false
					},
					rej => {
						this.tableLoading = false
					}
				)
			},
			// 初始化锁定金额明细
			LockXJDetails() {
				this.detailsShow = false
			},

			closeDialog() {
				this.detailsData = []
				this.item = ''
				this.$emit('closeChildDialog')
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			//currentPage（当前页数） 改变时会触发
			handleCurrentChange(val) {
				this.currentPage = val;
				console.log(`当前页: ${val}`);
				this.getLockRecord()
			},
			//明细分页调整
			handleSizeChange1(val) {
				console.log(`每页 ${val} 条`);
			},
			//currentPage（当前页数） 改变时会触发
			handleCurrentChange1(val) {
				this.currentPage = val;
				console.log(`当前页: ${val}`);
				this.getLockRecord()
			}
		},
		watch: {
			showFlag(newVal, oldVal) {
				this.dialogTableVisible = newVal
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/ .el-dialog {
		background-image: url(../../../../assets/bgimg/dialog.png);
		filter: alpha(opacity=70);
		background-repeat: no-repeat;
		background-size: 70% 18%;
		-moz-background-size: 70% 18%;
	}

	.box {
		margin-top: 40px;
	}
	.demo-table-expand {
		padding: 0 10px;
		width: auto;
	}
	.demo-table-expand .el-form-item {
	    margin-bottom: 0;
		width: auto;
	  }

	.storeButton {
		top: 0;
		bottom: 0;
		width: 80px;
		height: 38px;
		color: #FFFFFF;
		margin-left: 20px;
		background-color: #1890FF;
	}

	.title {
		font-size: 17px;
		padding-top: 20px;
	}
</style>
